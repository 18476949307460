import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Link,
  SlideOptions,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react"
import Guarantee from "@components/animations/Guarantee"
import MenuLink from "@components/nav/MenuLink"
import { NavItems, SocialLinks } from "@data/nav"
import { MenuDrawerProps } from "@definitions/types"
import SvgClose from "@public/svg/misc/close.svg"
import NextLink from "next/link"
import { useTranslations } from "next-intl"
import { FaPhone } from "react-icons/fa6"

export default function MenuDrawer({ isOpen, onClose }: MenuDrawerProps) {
  const linkHoverColor = "white"
  const placement: SlideOptions["direction"] = useBreakpointValue({
    base: "right",
    lg: "top",
  })
  const t = useTranslations("nav")

  return (
    <Drawer
      isOpen={isOpen}
      placement={placement}
      onClose={onClose}
      size={"full"}
    >
      <DrawerOverlay />
      <DrawerContent borderRadius={0}>
        <DrawerCloseButton
          bg={"brand.secondary"}
          color={"white"}
          borderRadius={"full"}
          width={12}
          height={12}
          top={{ base: 8, md: 12 }}
          right={{ base: 6, md: 12 }}
        >
          <Icon
            as={SvgClose}
            width={"1.5em"}
            height={"1.5em"}
            focusable={"false"}
            aria-hidden
          />
        </DrawerCloseButton>
        <DrawerBody
          padding={0}
          position={"absolute"}
          left={0}
          right={0}
          top={0}
          bottom={0}
          overflow={{ base: "auto", md: "hidden" }}
          zIndex={-1}
          bg={"white"}
        >
          <Flex
            justifyContent={"center"}
            height={{ base: "auto", md: "full" }}
            direction={{ base: "column", md: "row" }}
          >
            <Flex
              backgroundColor={"gray.200"}
              flex={1}
              alignItems={"center"}
              justifyContent={"center"}
              px={{ base: 10, md: 0 }}
              py={{ base: 10, md: 0 }}
              direction={"column"}
            >
              <VStack
                spacing={4}
                alignItems={{ base: "center", md: "flex-start" }}
              >
                <MenuLink
                  href={"/"}
                  label={t("home")}
                />
                {NavItems.map((navItem, index) => (
                  <MenuLink
                    href={navItem.href ?? "#"}
                    label={t(navItem.label)}
                    key={index}
                  />
                ))}
                <Flex
                  borderTopWidth={2}
                  borderColor={"brand.secondary"}
                  borderStyle={"solid"}
                  width={"full"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  pt={6}
                >
                  <Link
                    as={NextLink}
                    href={`tel:+${process.env.NEXT_PUBLIC_PHONE_NUMBER}`}
                    textAlign={"start"}
                    _hover={{ textDecoration: "none" }}
                  >
                    <HStack>
                      <Icon
                        as={FaPhone}
                        w={10}
                        h={10}
                        bg={"white"}
                        borderRadius={"full"}
                        p={2}
                      />
                      <Box
                        as={"span"}
                        textTransform={"uppercase"}
                        fontWeight={"700"}
                        fontSize={"lg"}
                      >
                        {t("call_us")}
                      </Box>
                    </HStack>
                  </Link>
                </Flex>
                <HStack
                  borderTopWidth={2}
                  borderColor={"brand.secondary"}
                  borderStyle={"solid"}
                  pt={6}
                  spacing={2}
                  width={"full"}
                  flexWrap={"wrap"}
                >
                  {SocialLinks.map((socialLink, index) => (
                    <MenuLink
                      key={index}
                      href={socialLink.href ?? "#"}
                      label={t(socialLink.label)}
                      fontSize={"sm"}
                      textTransform={"capitalize"}
                      target={"_blank"}
                      p={2}
                    />
                  ))}
                </HStack>
              </VStack>
            </Flex>
            <Flex
              flex={1}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <VStack
                spacing={{ base: 10, md: 20 }}
                my={{ base: 10, md: 0 }}
              >
                <Guarantee
                  w={{ base: 40, md: 60 }}
                  h={{ base: 40, md: 60 }}
                  loop={false}
                  play={false}
                />
                <Link
                  as={NextLink}
                  href={"/contact"}
                  fontSize={{ base: "xl", md: "3xl" }}
                  fontWeight={700}
                  px={10}
                  py={{ base: 2, md: 4 }}
                  textTransform={"uppercase"}
                  backgroundColor={{
                    base: "brand.secondary",
                    md: "transparent",
                  }}
                  borderColor={"brand.secondary"}
                  borderTopWidth={2}
                  borderBottomWidth={2}
                  borderRadius={{ base: 8, md: 0 }}
                  borderStyle={"solid"}
                  color={{ base: "white", md: "brand.secondary" }}
                  _focus={{ boxShadow: "none" }}
                  aria-label={"Contact"}
                  width={"full"}
                  transition={`all 0.3s ease-in-out`}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                    backgroundColor: "brand.secondary",
                  }}
                >
                  {t("demo_button_text")}
                </Link>
              </VStack>
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
